import {
  // validatePassword,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import type { Auth } from "firebase/auth";
import { getUserDetails } from "./user";

export type UserDetails = {
  emailAddress: string;
  password: string;
};

class PasswordValidationError extends Error {
  code: string;
  customError: boolean;

  constructor(message: string) {
    super(message);
    this.code = message;
    this.customError = true;
    this.name = "PasswordValidationError";
  }
}

/**
 * Get the Firebase Auth object from the Nuxt app.
 *
 * @returns {Auth} The Firebase Auth object.
 * @throws {Error} If the Firebase Auth object is not found.
 */
const getFirebaseAuth = () => {
  const nuxtApp = useNuxtApp();
  return nuxtApp.$auth as Auth;
};

export interface AtlasAuthTokenResponse {
  access_token: string;
  refresh_token: string;
  user_id: string;
  device_id: string;
}

/**
 * Get access and refresh token from Mongodb atlas
 *
 * @param idToken
 * @returns AtlasAuthTokenResponse
 */
export const getAccessAndRefreshToken = async (
  idToken: string
): Promise<AtlasAuthTokenResponse> => {
  const runtimeConfig = useRuntimeConfig();
  const graphqlTokenUrl =
    runtimeConfig.public.graphqlUrlBase + "/auth/providers/custom-token/login";
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const data = await $fetch(graphqlTokenUrl, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      token: idToken,
    }),
    redirect: "follow",
  });

  return data as AtlasAuthTokenResponse;
};

/**
 * Check if the password is valid
 *
 * @param password
 * @returns Promise<string>
 */
const isValidPassword = async (password: string) => {
  return new Promise((resolve, reject) => {
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$&*~])/;

    const isValid = regex.test(password);

    if (!isValid) {
      reject(
        new PasswordValidationError(
          "Password should contain at least : \nOne upper case\nOne lower case\nOne digit and one special character"
        )
      );
    } else {
      resolve("Password is valid.");
    }
  });
};

/**
 * Sign up a user
 *
 * @param userDetails
 * @returns Promise<any>
 */
export const signUp = async (userDetails: UserDetails) => {
  const auth = getFirebaseAuth();
  await isValidPassword(userDetails.password);
  const { user } = await createUserWithEmailAndPassword(
    auth,
    userDetails.emailAddress,
    userDetails.password
  );
  return user;
};

/**
 * Sign in a user
 *
 * @param userDetails
 * @returns Promise<void>
 */
export const signIn = async (userDetails: UserDetails, redirectTo: string) => {
  const auth = getFirebaseAuth();
  const userStore = useUserStore();
  const { user } = await signInWithEmailAndPassword(
    auth,
    userDetails.emailAddress,
    userDetails.password
  );
  const idToken: string = await user.getIdToken();
  const response = await getAccessAndRefreshToken(idToken);
  const accessToken = useCookie("access_token");
  accessToken.value = response.access_token;
  localStorage.setItem("refresh_token", response.refresh_token);
  // Fetch the user detail after login
  try {
    const data = await getUserDetails(idToken);
    userStore.changeUser({
      ...data.user,
      email: user.email,
    } as unknown as User);
    localStorage.setItem("userId", data.user.userId);
    useRouter().push(redirectTo);
  } catch (error) {
    //
  }
};

/**
 * Send reset password email
 *
 * @param email
 * @returns Promise<void>
 */
export const sendResetPasswordEmail = async (email: string) => {
  const auth = getFirebaseAuth();
  await sendPasswordResetEmail(auth, email);
};

/**
 * Log out a user
 *
 * @returns Promise<void>
 */
export const logOut = async () => {
  try {
    await signOut(getFirebaseAuth());
    const accessToken = useCookie("access_token");
    accessToken.value = "";
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("userId");

    // clear user store
    useUserStore().changeUser({});
    // navigate to login page
    useRouter().push("/auth/signin");
  } catch (error) {
    console.log(error);
  }
};
